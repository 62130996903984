import i18n, { InitOptions, Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import translatedLanguages from "./translation/translation.json";

const resources: Resource = {
  hi: { translation: translatedLanguages.hi },
  en: { translation: translatedLanguages.en },
  ID: { translation: translatedLanguages.ID },
  it: { translation: translatedLanguages.it },
  UA: { translation: translatedLanguages.UA },
  bg: { translation: translatedLanguages.bg },
  CZ: { translation: translatedLanguages.CZ },
  HU: { translation: translatedLanguages.HU },
  ro: { translation: translatedLanguages.ro },
  il: { translation: translatedLanguages.il },
  DK: { translation: translatedLanguages.DK },
  nl: { translation: translatedLanguages.nl },
  SK: { translation: translatedLanguages.SK },
  ar: { translation: translatedLanguages.ar },
  de: { translation: translatedLanguages.de },
  NO: { translation: translatedLanguages.NO },
  fi: { translation: translatedLanguages.fi },
  Th: { translation: translatedLanguages.Th },
  pl: { translation: translatedLanguages.pl },
  SE: { translation: translatedLanguages.SE },
  kr: { translation: translatedLanguages.kr },
  ES: { translation: translatedLanguages.ES },
  TR: { translation: translatedLanguages.TR },
  jp: { translation: translatedLanguages.jp },
  fr: { translation: translatedLanguages.fr },
  Ru: { translation: translatedLanguages.Ru },
  GR: { translation: translatedLanguages.GR },
  CN: { translation: translatedLanguages.CN },
};

const Options: InitOptions = {
  resources: resources,
  lng: "en",
};

i18n.use(initReactI18next).init(Options);

export default i18n;
