import { IGameLikeDisLikePayload } from "../../types/api-payload.types";
import { IGame } from "../../types/game.types";
import { REQUEST_CONTENT_TYPE, REQUEST_METHODS, doFetch } from "../fetcher";

export const gameApis = {
  GetAllGames: () => doFetch(`/games`, REQUEST_METHODS.GET),
  GetFeaturedAllGames: (isMobile: boolean) =>
    doFetch(`/games/featured?isMobile=${isMobile}`, REQUEST_METHODS.GET),
  // getGames: async (page:number,size:number) => {
  //   return await doFetch(`/games/?size=${size}&page=${page}`);
  // },
  getGames: async (pageSize: number, page: number, isMobile: boolean) => {
    return await doFetch(
      `/games?size=${pageSize}&page=${page}&isMobile=${isMobile}`,
      REQUEST_METHODS.GET
    );
  },
  // getGames: async () => {
  //   return await doFetch(`/games`);
  // },
  getGameById: async (id: string) => {
    return await doFetch(`/games/${id}`);
  },
  getSearchGame: async (query: string) => {
    return await doFetch(`/games/search${query}`);
  },

  getGameTypes: async () => {
    return await doFetch(`/games/games-types`);
  },

  getGameAllTypes: async () => {
    return await doFetch(`/games/category/fetch-all`);
  },

  getGameByType: (gameType: string, isMobile: boolean) =>
    doFetch(`/games/game-by-type?type=${gameType}&isMobile=${isMobile}`),

  createGameLikeDisLike: (payload: IGameLikeDisLikePayload) =>
    doFetch(`/games/like-dislike`, {
      method: REQUEST_METHODS.POST,
      body: payload,
    }),

  updateGameLikeDisLike: (
    gameLikeDislikeObjId: string,
    payload: IGameLikeDisLikePayload
  ) =>
    doFetch(`/games/like-dislike/${gameLikeDislikeObjId}`, {
      method: REQUEST_METHODS.PUT,
      body: payload,
    }),
  updateGame: (payload: any, id: string | undefined) =>
    doFetch(`/games/${id}`, {
      method: REQUEST_METHODS.PUT,
      body: payload,
    }),
  updateGameWithFileUpload: (payload: any, id: string | undefined) =>
    doFetch(`/games/updateGameWithFileUpload/${id}`, {
      method: REQUEST_METHODS.PUT,
      body: payload,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  createGame: (payload: IGame) =>
    doFetch("/games/", {
      method: REQUEST_METHODS.POST,
      body: payload,
    }),
  createGameWithFileUpload: (payload: any) =>
    doFetch("/games/createGameWithFileUpload", {
      method: REQUEST_METHODS.POST,
      body: payload,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  createGameWithZipUpload: (payload: any) =>
    doFetch("/games/upload-game-zip", {
      method: REQUEST_METHODS.POST,
      body: payload,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  deleteGame: (id: string) =>
    doFetch(`/games/${id}`, {
      method: REQUEST_METHODS.DELETE,
    }),
  deleteVideo: (id: string) =>
    doFetch(`/videos/delete/${id}`, {
      method: REQUEST_METHODS.DELETE,
    }),
  editVideo: (payload: any) =>
    doFetch(`/videos/edit`, {
      method: REQUEST_METHODS.PUT,
      body: payload,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  getGameBlogs: (blogId?: string | undefined | null) =>
    doFetch(`/games/blogs/${blogId ?? ""}`),

  getAllBlogs: (page: number, size: number) =>
    doFetch(`/blog/recently?page=${page ?? 1}&size=${size ?? 5}`),

  CreateGameBlogs: (payload: any) =>
    doFetch(`/blog/create`, {
      method: REQUEST_METHODS.POST,
      body: payload,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  UpdateGameBlogs: (id: string, payload: any) =>
    doFetch(`/blog/update/${id}`, {
      method: REQUEST_METHODS.PUT,
      body: payload,
      contentType: REQUEST_CONTENT_TYPE.MULTIPART,
    }),
  DeleteGameBlogs: (id: string) =>
    doFetch(`/blog/delete/${id}`, {
      method: REQUEST_METHODS.DELETE,
    }),
  GameLikeByPlayer: (payload: { gameId: string }) =>
    doFetch(
      `/games/player/like`,
      {
        method: REQUEST_METHODS.POST,
        body: payload,
      },
      true
    ),
  GetPlayerProfileById: (playerId: String) =>
    doFetch(
      `/games/player-profile/${playerId}`,
      {
        method: REQUEST_METHODS.GET,
      },
      true
    ),
  GetFeedBack: () => doFetch(`/feedbacks/get`),
  CreateFeedBack: (payload: any) =>
    doFetch(`/games/feedback`, {
      method: REQUEST_METHODS.POST,
      body: payload,
    }),
  getDashboardData: () => doFetch(`/admin/dashboard`, REQUEST_METHODS.GET),
  getAllGameList: () => doFetch(`/games/list`, REQUEST_METHODS.GET),
  getBlogById: (blogId: String) =>
    doFetch(
      `/blog/${blogId}`,
      {
        method: REQUEST_METHODS.GET,
      },
      true
    ),

  getCategoryById: async (categoryId: any) => {
    return await doFetch(`/games/category/fetch/${categoryId}`);
  },

  createCategory: (payload: any) =>
    doFetch(`/games/category/create`, {
      method: REQUEST_METHODS.POST,
      body: payload,
    }),
  updateCategory: (categoryId: any, payload: any) =>
    doFetch(`/games/category/edit/${categoryId}`, {
      method: REQUEST_METHODS.PUT,
      body: payload,
    }),
  deleteCategory: (categoryId: string) =>
    doFetch(`/blog/delete/${categoryId}`, {
      method: REQUEST_METHODS.DELETE,
    }),
};
