import React from "react";
import Select from "react-select";
import "./ReactSelector.scss";

const ReactSelectBasic = ({ isRequired, label, error, ...props }: any) => {
  const CustomStyle = {
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "grey" : "grey",
      borderRadius: "4px",
    }),
  };
  return (
    <div className="cus-react-selector-container">
      {label && (
        <p className="mb-2 text-start cus-react-selector-label">
          {label}
          {isRequired && <span className="require-star">*</span>}
        </p>
      )}
      <Select
        styles={CustomStyle}
        className="basic-single"
        classNamePrefix="select"
        {...props}
      />
      {error && (
        <p className="text-danger m-0 text-start cus-react-selector-error">
          {error}
        </p>
      )}
    </div>
  );
};

export default ReactSelectBasic;
