import React, { Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ContectUs from "../pages/contectUs/ContectUs";
import DashboardPage from "../pages/adminPages/dashboard/DashboardPage";
import Category from "../pages/adminPages/category/Category";
import CreateCategory from "../pages/adminPages/category/CreateCategory";
// import FeedbackDisplay from "../pages/adminPages/feedback/FeedbackDisplay";

const Home = React.lazy(() => import("../pages/Home/Home"));
const Layout = React.lazy(() => import("../components/layout"));
const Game = React.lazy(() => import("../pages/Game/Game"));
const PrivacyPolicy = React.lazy(
  () => import("../pages/PrivacyPolicy/PrivacyPolicy")
);
const Navbar = React.lazy(
  () => import("../components/common/AdminCommon/navbar/NavBar")
);
const LoadingIndicator = React.lazy(
  () => import("../components/common/loader/LoadingIndicator")
);
const PageNotFound = React.lazy(
  () => import("../pages/404PageNotFound/PageNotFound")
);
const BlogsDetailsPage = React.lazy(
  () => import("../pages/Blogs/BlogsDetailsPage")
);
const BlogsListPage = React.lazy(() => import("../pages/Blogs/BlogsListPage"));
const BlogForm = React.lazy(() => import("../pages/adminPages/blogs/BlogForm"));
const BlogListPage = React.lazy(
  () => import("../pages/adminPages/blogs/BlogListPage")
);
const VideoPage = React.lazy(
  () => import("../pages/adminPages/videos/VideosPage")
);
const Games = React.lazy(() => import("../pages/adminPages/games/Games"));
const GameFormPage = React.lazy(
  () => import("../pages/adminPages/games/SiblingComponants/GameFormPage")
);
const Login = React.lazy(() => import("../pages/login/Login"));
const AppProtectedRouters = React.lazy(
  () => import("./admin-routes/AppProtectedRouters")
);
const AppUnProtectedRouters = React.lazy(
  () => import("./admin-routes/AppUnProtectedRouters")
);
const ThirdPartyGames = React.lazy(
  () => import("../pages/adminPages/games/ThirdPartyGames")
);
const FeedbackDisplay = React.lazy(
  () => import("../pages/adminPages/feedback/FeedbackDisplay")
);

function AppRouter() {
  const location = useLocation();

  useEffect(() => {
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = "manual";
    }
    return () => {
      window.history.scrollRestoration = "auto";
    };
  }, [location]);
  return (
    <>
      <Suspense fallback={<LoadingIndicator isOpen />}>
        {/* <ScrollToTop /> */}
        <Routes>
          {/* commmented on 28 may to run the admin */}
          {/* <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="category/:gameType" element={<Home />} />
            <Route path="game/:gameId" element={<Game />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="blogs" element={<BlogsListPage />} />
            <Route path="blogs/:id" element={<BlogsDetailsPage />} />
            <Route path="contact-us" element={<ContectUs />} />
            <Route path="*" element={<PageNotFound />} />
          </Route> */}

          {/* **********************add language ************** */}

          {/* <Route path="/:languageCode" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="category/:gameType" element={<Home />} />
            <Route path="game/:gameId" element={<Game />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="blogs" element={<BlogsListPage />} />
            <Route path="blogs/:id" element={<BlogsDetailsPage />} />
            <Route path="*" element={<PageNotFound />} />
          </Route> */}

          {/* ********************** note :add language ************** */}

          {/* Admin Router */}
          <Route element={<AppUnProtectedRouters />}>
            <Route path='/' element={<Login />} />
          </Route>
          {/* user Router */}
          {/* <Route element={<AppUserProtectedRouters />}>
          <Route element={<Navbar isUser={true} />}>
            <Route
              path="/user/dashboard"
              element={<DashBoard isUser={true} />}
            />
            <Route
              path="/user/games/blogs"
              element={<BlogListPage isUser={true} />}
            />
            <Route
              path="/user/games/blogs/add-blog"
              element={<BlogForm isUser={true} />}
            />
            <Route
              path="/user/games/blogs/update-blog/:id"
              element={<BlogForm isUser={true} />}
            />
          </Route>
        </Route> */}
          {/*admin dashboard */}
          <Route element={<AppProtectedRouters />}>
            <Route element={<Navbar />}>
              <Route path='/dashboard' element={<DashboardPage />} />
              <Route path='/videos' element={<VideoPage />} />
              <Route
                path='/games/third-party-game'
                element={<ThirdPartyGames />}
              />
              <Route path='/games' element={<Games />} />
              <Route path='/games/game-form' element={<GameFormPage />} />
              <Route path='/games/game-form/:id' element={<GameFormPage />} />
              <Route path='/games/blogs' element={<BlogListPage />} />
              <Route path='/games/blogs/add-blog' element={<BlogForm />} />
              <Route
                path='/games/blogs/update-blog/:id'
                element={<BlogForm />}
              />
              <Route path='/games/feedback' element={<FeedbackDisplay />} />
              <Route path='/category' element={<Category />} />
              <Route path='/category/create' element={<CreateCategory />} />
              <Route path='/category/edit/:categoryId' element={<CreateCategory />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default AppRouter;
