import RefreshIcon from "@mui/icons-material/Refresh";
import { useEffect, useState } from "react";
import { gameApis } from "../../../services/games/gameApi";
import CircularLoader from "../../../components/common/loader/CircularLoader";

const DashboardPage = () => {
  const [dashBoardData, setDashboardData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const res = await gameApis.getDashboardData();
      if (res.data) {
        const fetchData = res?.data?.response[0];
        setDashboardData(fetchData);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <div className="container-fluid mt-3">
      <div className="d-flex justify-content-end pe-2">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            fetchDashboardData();
            setIsLoading(true);
          }}
        >
          <RefreshIcon /> Refresh
        </button>
      </div>
      <div className="row">
        <div className="col-xl-5 col-lg-6">
          <div className="row gy-3">
            <div className="col-sm-6">
              <div className="card widget-flat">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-account-multiple widget-icon"></i>
                  </div>
                  <h5 className="text-muted fw-normal mt-0" title="Total Games">
                    Total Games
                  </h5>
                  <h3 className="mt-3">{dashBoardData?.total_game}</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card widget-flat">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-account-multiple widget-icon"></i>
                  </div>
                  <h5
                    className="text-muted fw-normal mt-0"
                    title="Total Videos"
                  >
                    Total Videos
                  </h5>
                  <h3 className="mt-3">{dashBoardData?.total_video}</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card widget-flat">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-account-multiple widget-icon"></i>
                  </div>
                  <h5
                    className="text-muted fw-normal mt-0"
                    title="Total Playes"
                  >
                    Total Players
                  </h5>
                  <h3 className="mt-3">{dashBoardData?.total_player}</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card widget-flat">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-account-multiple widget-icon"></i>
                  </div>
                  <h5
                    className="text-muted fw-normal mt-0"
                    title="Total feedback"
                  >
                    Total feedback
                  </h5>
                  <h3 className="mt-3">{dashBoardData?.total_feedback}</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card widget-flat">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-account-multiple widget-icon"></i>
                  </div>
                  <h5
                    className="text-muted fw-normal mt-0"
                    title="Total feedback"
                  >
                    Total blogs
                  </h5>
                  <h3 className="mt-3">{dashBoardData?.total_blog}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
