import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputBase,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useMemo, useState } from "react";
import DataTable, {
  TableColumn,
  TableStyles,
} from "react-data-table-component";
import { IGame } from "../../../types/game.types";
import "./ReactDataTable.scss";

export interface IReactDataTable {
  data: any[];
  isGameTable?: boolean;
  isHeaderBtn: boolean;
  headerBtnToRedirect: any;
  headerBtnName: string;
  columns: TableColumn<any>[];
  loading: boolean;
  totalRowSize?: number;
  handlePageChange?: any;
  currentPage?: any;
  handleRowChange?: any;
}

const ReactDataTable = ({
  data,
  isGameTable,
  columns,
  loading,
  isHeaderBtn,
  headerBtnToRedirect,
  headerBtnName,
  totalRowSize,
  handlePageChange,
  currentPage,
  handleRowChange,
}: IReactDataTable) => {
  const [searchText, setsSearchText] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [isPopular, setIsPopular] = useState(false);
  const [isTrending, setIsTrending] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const searchData = useMemo(() => {
    const ShortBarFilterData = data?.filter((gameDetails: IGame) => {
      if (!isFeatured && !isPopular && !isMobile && !isDesktop && !isTrending)
        return true;

      return (
        (isTrending && Boolean(gameDetails.top_rated)) ||
        (isFeatured && Boolean(gameDetails.featured)) ||
        (isPopular && Boolean(gameDetails.popular)) ||
        (isMobile && gameDetails.mobile === "true") ||
        (isDesktop && gameDetails.mobile === "false")
      );
    });
    const filterData = ShortBarFilterData?.filter((gameDetails: IGame) => {
      return (
        (gameDetails.name &&
          gameDetails.name
            .toLowerCase()
            .includes(searchText?.toLowerCase() ?? "")) ||
        (gameDetails.description &&
          gameDetails.description
            .toLowerCase()
            .includes(searchText?.toLowerCase() ?? "")) ||
        (gameDetails._id &&
          gameDetails._id
            .toLowerCase()
            .includes(searchText?.toLowerCase() ?? "")) ||
        (gameDetails.title &&
          gameDetails.title
            .toLowerCase()
            .includes(searchText?.toLowerCase() ?? ""))
      );
    });
    return filterData;
  }, [
    data,
    isFeatured,
    isPopular,
    isMobile,
    isDesktop,
    isTrending,
    searchText,
  ]);

  const dataTableCostumeStyle: TableStyles = {
    table: {
      style: {},
    },
    headRow: {
      style: {
        // backgroundColor: "beige",
        // height: "50px",
        borderRadius: "10px 10px 0px 0px",
        fontWeight: "bold",
        fontSize: "15px",
      },
    },
    head: {
      style: {
        zIndex: 3,
      },
    },
    headCells: {
      style: {
        position: "sticky",
        "&:first-child ,&:last-child": {
          position: "sticky",
          backgroundColor: "#fff",
          zIndex: 2,
        },
        "&:first-child": {
          left: 0,
        },
        "&:last-child": {
          right: 0,
        },
      },
    },
    cells: {
      style: {
        "&:first-child ,&:last-child": {
          position: "sticky",
          backgroundColor: "#fff",
          zIndex: 2,
        },
        "&:first-child": {
          left: 0,
        },
        "&:last-child": {
          right: 0,
        },
      },
    },
    rows: {
      style: {
        fontSize: "15px",
        // backgroundColor: "red",
      },
    },
  };

  return (
    <>
      <Box margin={3} padding={1} sx={{ boxShadow: 2 }}>
        <div className="d-flex align-items-center justify-content-between px-2">
          <Box display="flex" gap={2}>
            <Stack
              className="bg-white mb-4 mt-4 p-1 ps-2"
              direction="row"
              boxShadow={"0px 0px 1px 1px #cdcdcd"}
              borderRadius={5}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                value={searchText}
                onChange={(e) => setsSearchText(e.target.value)}
                placeholder="Search..."
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Stack>
            {isGameTable && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTrending}
                      onChange={() => {
                        setIsTrending(!isTrending);
                      }}
                      name="Trending"
                    />
                  }
                  label="Trending"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFeatured}
                      onChange={() => {
                        setIsFeatured(!isFeatured);
                      }}
                      name="Featured"
                    />
                  }
                  label="Featured"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPopular}
                      onChange={() => {
                        setIsPopular(!isPopular);
                      }}
                      name="Featured"
                    />
                  }
                  label="Popular"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isMobile}
                      onChange={() => {
                        setIsMobile(!isMobile);
                      }}
                      name="Mobile"
                    />
                  }
                  label="Mobile"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDesktop}
                      onChange={() => {
                        setIsDesktop(!isDesktop);
                      }}
                      name="Desktop"
                    />
                  }
                  label="Desktop"
                />
              </>
            )}
          </Box>
          {isHeaderBtn && (
            <>
              <div className="d-flex gap-2">
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/games/third-party-game")}
                >
                  Add Third party game
                </Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={/* handleOpenModal */ navigate("/game/game-form");}
                  onClick={headerBtnToRedirect}
                >
                  {headerBtnName}
                </Button>
              </div>
            </>
          )}
        </div>
        {isGameTable ? (
          <DataTable
            customStyles={dataTableCostumeStyle}
            columns={columns}
            data={searchData}
            fixedHeader={true}
            fixedHeaderScrollHeight="75vh"
            progressPending={loading}
            pagination
            dense
          />
        ) : (
          <DataTable
            customStyles={dataTableCostumeStyle}
            columns={columns}
            data={searchData}
            fixedHeader={true}
            fixedHeaderScrollHeight="75vh"
            progressPending={loading}
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Customize options as per your requirement
            paginationTotalRows={totalRowSize}
            onChangeRowsPerPage={handleRowChange}
            onChangePage={handlePageChange}
            paginationServer
            paginationDefaultPage={currentPage}
            dense
          />
        )}
      </Box>
    </>
  );
};

export default ReactDataTable;
