import { Breadcrumbs, Button, Chip, FormHelperText } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import PrimaryButton from "../../../components/forms/button/PrimaryButton";
import ReactSelectBasic from "../../../components/forms/react-selector/ReactSelecte";
import ReactRte from "../../../components/forms/TextEditer/ReactRte";
import { gameApis } from "../../../services/games/gameApi";
import {
  ArrayToReactSelectOutput,
  draftToString,
  stringToDraft,
} from "../../../utils/helper";
import "./Category.scss";

const CreateCategory = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [categoryArray, setCategoryArray] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    categoryDescription: "",
    gameType: "",
  });

  const getAllCategory = async () => {
    try {
      const response: any = await gameApis.getGameTypes();
      if (response.status === 200) {
        setCategoryArray(response?.data?.response);
      }
    } catch (error: any) {
      toast.error(error?.msg || "Error fetching categories");
    }
  };

  const getCategoryById = async (categoryId: string) => {
    try {
      const response: any = await gameApis.getCategoryById(categoryId);
      if (response?.status === 200) {
        const categoryData = response?.data?.response;
        const categoryDescription = stringToDraft(
          categoryData?.description || ""
        );
        const gameType = categoryData?.name || "";

        setInitialValues({
          categoryDescription,
          gameType,
        });

        setIsEdit(true);
      }
    } catch (error) {
      console.log("Error fetching category by ID:", error);
      toast.error("Error fetching category details.");
    }
  };

  useEffect(() => {
    getAllCategory();

    if (categoryId) {
      getCategoryById(categoryId);
    }
  }, [categoryId]);

  const validationSchema = Yup.object().shape({
    categoryDescription: Yup?.object()?.test(
      "has text",
      "Cannot save an empty note",
      (value: any) => value?.getCurrentContent()?.hasText()
    ),
    gameType: Yup?.string()?.required("Game Type is required"),
  });

  const handleAddCategory = async (
    payload: any,
    setSubmitting: any,
    resetForm: any
  ) => {
    try {
      let response;
      if (!isEdit) {
        response = await gameApis.createCategory(payload);
        toast.success(response?.data?.msg);
        navigate("/category");
      } else {
        response = await gameApis.updateCategory(categoryId, payload);
        toast.success(response?.data?.msg);
        navigate("/category");
      }

      resetForm();
      setSubmitting(false);

      setInitialValues({
        categoryDescription: stringToDraft(
          response?.data?.response?.description || ""
        ),
        gameType: response?.data?.response?.name || "",
      });
    } catch (error: any) {
      toast.error("Error processing category.");
      setSubmitting(false);
    }
  };

  const categoryArrayOption = ArrayToReactSelectOutput(categoryArray);

  return (
    <div className="category-form-container">
      <div className="d-flex justify-content-between align-items-center m-3 category-form-page-title-container">
        <span className="page-title">
          {isEdit ? `Update Category` : `Create Category`}
        </span>
        <Breadcrumbs aria-label="breadcrumb">
          <Chip
            label="Category"
            onClick={() => navigate("/category")}
            sx={{
              cursor: "pointer",
              "&:hover, &:focus": {
                backgroundColor: "white",
              },
            }}
          />
          <span>{isEdit ? `Update Category` : `Create Category`}</span>
        </Breadcrumbs>
      </div>
      <div className="p-3 d-flex flex-column gap-4 category-form-container">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validateOnBlur
          validateOnChange
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleAddCategory(
              {
                description: draftToString(values.categoryDescription),
              },
              setSubmitting,
              resetForm
            );
          }}
          validationSchema={validationSchema}
        >
          {(formikProps) => {
            return (
              <>
                <form onSubmit={formikProps.handleSubmit}>
                  <div className="row category-form-info-container">
                    <p className="m-0 p-3 text-start form-fields-title">
                      Category Information
                    </p>
                    <div className="row m-0 category-info-container">
                      <div className="d-flex flex-column gap-4 mb-3 p-3 col-sm-12 col-lg-12 category-info-first-div">
                        <div className="row category-fields g-3">
                          <div className="col-lg-4">
                            <ReactSelectBasic
                              isRequired
                              label="Game Type"
                              name="gameType"
                              value={{
                                label: formikProps.values.gameType,
                                value: formikProps.values.gameType,
                              }}
                              theme={(theme: any) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#C0C0C0",
                                  primary: "grey",
                                },
                              })}
                              error={
                                formikProps.errors.gameType &&
                                formikProps.touched.gameType
                                  ? formikProps.errors.gameType
                                  : null
                              }
                              isRtl={false}
                              isClearable={false}
                              isSearchable={false}
                              options={categoryArrayOption}
                              onChange={(valueObj: any) => {
                                valueObj?.value &&
                                  formikProps.setFieldValue(
                                    "gameType",
                                    valueObj.value
                                  );
                              }}
                              onBlur={formikProps.handleBlur}
                              isDisabled={true}
                            />
                          </div>
                          <div className="mb-2 p-3 categoryDescription-react-rtl-container">
                            <ReactRte
                              value={formikProps?.values?.categoryDescription}
                              setValue={(value: any) => {
                                formikProps.setFieldValue(
                                  "categoryDescription",
                                  value
                                );
                              }}
                              onBlur={() => {
                                formikProps.setFieldTouched(
                                  "categoryDescription",
                                  true
                                );
                              }}
                              error={
                                (!!formikProps.errors.categoryDescription &&
                                  formikProps.touched
                                    .categoryDescription) as boolean
                              }
                            />
                            {formikProps.errors.categoryDescription &&
                              formikProps.touched.categoryDescription && (
                                <FormHelperText className="text-danger">
                                  {
                                    formikProps?.errors
                                      ?.categoryDescription as string
                                  }
                                </FormHelperText>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="text-center d-flex justify-content-end gap-2">
                      {isEdit && (
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => navigate(`/category`)}
                        >
                          Cancel
                        </Button>
                      )}
                      <PrimaryButton
                        type="submit"
                        variant="contained"
                        className="btn btn-primary"
                        disabled={
                          formikProps.isSubmitting ||
                          (isEdit && !formikProps.dirty)
                        }
                        loading={formikProps.isSubmitting}
                      >
                        {isEdit ? "Update" : "Submit"}
                      </PrimaryButton>
                      {!isEdit && (
                        <PrimaryButton type="Reset" variant="outlined">
                          {"Reset"}
                        </PrimaryButton>
                      )}
                    </div>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CreateCategory;
