import React, { FC } from "react";
import { Editor, EditorProps } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./ReactRte.scss";
interface IReactRte extends EditorProps {
  value: any;
  setValue: (value: any) => void;
  error: boolean;
}

const ReactRte: FC<IReactRte> = ({ value, setValue, error, ...props }) => {
  // const toolbarConfig = {
  //   options: [
  //     "inline",
  //     "blockType",
  //     "fontSize",
  //     "list",
  //     "textAlign",
  //     "colorPicker",
  //     "link",
  //     "embedded",
  //     "emoji",
  //     "image",
  //   ],
  //   inline: {
  //     options: ["bold", "italic", "underline", "strikethrough", "monospace"],
  //   },
  //   blockType: {
  //     inDropdown: true,
  //   },
  //   fontSize: {
  //     options: ["10", "12", "14", "16", "18", "24", "36"],
  //   },
  //   list: {
  //     options: ["unordered", "ordered"],
  //   },
  //   textAlign: {
  //     options: ["left", "center", "right", "justify"],
  //   },
  //   colorPicker: {
  //     colors: ["#FF0000", "#00FF00", "#0000FF"],
  //   },
  //   link: {
  //     inDropdown: true,
  //   },
  //   embedded: {},
  //   emoji: {},
  //   image: {
  //     uploadCallback: () => {},
  //     alt: { present: true, mandatory: true },
  //     defaultSize: {
  //       height: "auto",
  //       width: "auto",
  //     },
  //   },
  // };

  return (
    <div>
      <Editor
        editorState={value}
        onEditorStateChange={setValue}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        // toolbar={toolbarConfig}
        {...props}
      />
    </div>
  );
};

export default ReactRte;
