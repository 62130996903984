import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactDataTable from "../../../components/AdminComponent/reactDataTable/ReactDataTable";
import DeleteDialog from "../../../components/common/AdminCommon/navbar/DeleteDialog";
import { gameApis } from "../../../services/games/gameApi";
import { IGame } from "../../../types/game.types";
import "./Category.scss";

const Category = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [categoryId, setDeleteCategoryId] = useState("");
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [categoryPerPage, setCategoryPerPage] = useState(10);

  useEffect(() => {
    getAllCategory();
  }, [currentPage, categoryPerPage]);

  const getAllCategory = async () => {
    setLoading(true);
    try {
      const response: any = await gameApis.getGameAllTypes();
      if (response.status === 200) {
        setCategoryList(response?.data?.response);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.status === 400) {
        toast.error(error.msg);
      }
    }
  };

  const handleOpenDeleteDialog = (id: string) => {
    setOpenDeleteDialog(true);
    setDeleteCategoryId(id);
  };

  const handleDeleteCategory = async () => {
    try {
      const response: any = await gameApis.deleteGame(categoryId);
      if (response?.status === 200) {
        getAllCategory();
        setOpenDeleteDialog(false);
        toast.success("Deleted successfully!");
        setDeleteCategoryId("");
      }
    } catch (error: any) {
      if (error.status === 400) {
        setDeleteCategoryId("");
        setOpenDeleteDialog(false);
        toast.error(error.msg);
      }
    }
  };

  const columns: TableColumn<IGame>[] = [
    {
      name: "Sr",
      maxWidth: "20px",
      //   center: true,
      cell: (_: any, index: number) => <span>{index + 1}</span>,
      sortable: true,
    },
    {
      name: "Category Name",
      // minWidth: "400px",
      //   center: false,
      style: { textAlign: "start" },
      cell: (row: any) => (
        <div>
          <span className='mb-3'>{row?.name}</span>
          <div className='d-flex flex-wrap'>
            {row.popular && (
              <span
                className='badge m-1'
                children='Popular'
                style={{ backgroundColor: "#d35400" }}
              />
            )}
            {row.top_rated && (
              <span
                className='badge m-1'
                children='Top rated'
                style={{ background: "#c44569" }}
              />
            )}
            {row.featured && (
              <span
                className='badge m-1'
                children='Featured'
                style={{ backgroundColor: "#f39c12" }}
              />
            )}
            {row.is_verified && (
              <span
                className='badge m-1'
                children='Verified'
                style={{ backgroundColor: "#2980b9" }}
              />
            )}
          </div>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      //   center: true,
      sortable: false,
      cell: (row: any, index: any) => (
        <div className='video-actions d-flex gap-3'>
          <EditIcon
            style={{
              cursor: "pointer",
            }}
            color='primary'
            onClick={() => navigate(`/category/edit/${row?._id}`)}
          />

          {/* <DeleteIcon
            style={{
              cursor: "pointer",
            }}
            color='error'
            // onClick={() => handleOpenDeleteDialog(row.pathName ?? "")}
          /> */}
        </div>
      ),
    },
  ];

  const handleRedirect = () => {
    navigate("/category/create");
  };

  return (
    <div className='category-container'>
      <ReactDataTable
        isGameTable={false}
        data={categoryList}
        columns={columns}
        loading={loading}
        headerBtnToRedirect={() => handleRedirect()}
        headerBtnName='Add New Category'
        isHeaderBtn={false}
      />
      {/* confirm is it delete popup */}
      <DeleteDialog
        deleteMsg={"Are you sure you want to delete this game ?"}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        handleDelete={handleDeleteCategory}
      />
    </div>
  );
};

export default Category;
