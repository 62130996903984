// import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import "./i18n/i18n";
import AppRouter from "./routes/app.router";

// import { useEffect } from "react";
// import ReactGA from "react-ga";

// ReactGA.initialize(TRACKING_ID);

function App() {
  // const orientation = useScreenOrientation();
  // console.log("orientation::::", orientation);

  // console.log("app document.title:::", document);
  // console.log("app window.location.href:::", window.location.href);

  // const { t, i18n } = useTranslation();
  // google analitics
  // const location = useLocation();
  // useEffect(() => {
  //   console.log("location.pathname:::", location.pathname);
  //   console.log("location.search:::", location.search);
  //   const GA = ReactGA.pageview(location.pathname + location.search);
  // }, [location]);

  // console.log(t("title"));
  // console.log("-----------------------");

  // i18n.changeLanguage("fr");
  // console.log(t("title"));

  return (
    <div className="App">
      {/* <HelmetProvider> */}
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
      {/* </HelmetProvider> */}
    </div>
  );
}

export default App;
