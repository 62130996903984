import React, { createContext, useContext, useState } from "react";

export interface IContextValue {
  isLoading?: boolean;
  isOpenPlayerAuth?: boolean;
}

export interface ICreateAppContext {
  contextValue: IContextValue;
  setContextValue: (value: IContextValue) => void;
}

export const CreateAppContext = createContext<ICreateAppContext>({
  contextValue: {},
  setContextValue: () => {},
});

const AppContextProvider = (props: any) => {
  const [contextValue, setContextUpdateValue] = useState<IContextValue>({
    isLoading: false,
    isOpenPlayerAuth: false,
  });

  const setContextValue = (valueObj: object) => {
    const newValue = { ...contextValue, ...valueObj };

    setContextUpdateValue(newValue);
  };

  return (
    <CreateAppContext.Provider
      value={{ contextValue, setContextValue }}
      {...props}
    ></CreateAppContext.Provider>
  );
};

// this custom hook will provide auth data to across the app
export const useAppContext = () => {
  const queryAppContext = useContext(CreateAppContext);
  return {
    ...queryAppContext,
  };
};
export default AppContextProvider;
