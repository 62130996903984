import { LoadingButton } from "@mui/lab";
// import { Button } from "@mui/material";

const PrimaryButton = (props: any) => {
  return (
    // <button className="btn btn-primary" {...props} />
    // <Button variant="contained" {...props} />
    <LoadingButton {...props} />
  );
};

export default PrimaryButton;
