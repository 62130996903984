import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
interface IRequestMethods {
  GET: string;
  POST: string;
  PUT: string;
  DELETE: string;
}

const instance: AxiosInstance = axios.create();

instance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (
      error?.response?.data?.msg === "Invalid token !" ||
      error?.response?.data?.msg === "Token is not found!"
    ) {
      // localStorage.clear();
      Cookies.remove("token");
      console.log("token:", error?.response?.data?.msg);
      // return window.location.replace("/");
    }
    throw error.response.data;
  }
);

export const REQUEST_METHODS: IRequestMethods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const REQUEST_CONTENT_TYPE = {
  JSON: "application/json",
  MULTIPART: "multipart/form-data",
};

const defaultReqDetails = {
  method: REQUEST_METHODS.GET,
  contentType: REQUEST_CONTENT_TYPE.JSON,
  body: {},
};

export const doFetch = (
  url: string,
  reqDetails: any = defaultReqDetails,
  isPlayer = false
) => {
  const {
    method = defaultReqDetails.method,
    body = defaultReqDetails.body,
    ...otherOptions
  } = reqDetails;

  const apiUrl = process.env.REACT_APP_API_URL;

  const { contentType = defaultReqDetails.contentType } = otherOptions ?? {};
  // console.log("api", `${FINAL_BASE_URL}${url}`);
  // console.log("method name", method);
  const options: AxiosRequestConfig = {
    // url: `http://localhost:8002/api/v1/games/games-types`,
    // url: `${FINAL_BASE_URL}${url}`,
    url: `${apiUrl}${url}`,
    method,
    headers: {
      "Content-Type": contentType,
      "ngrok-skip-browser-warning": true,
    },
  };

  // const token = StorageManager.get("token");
  const token = localStorage.getItem("token");
  const playerToken = Cookies.get("playerToken");
  // const playerToken = StorageManager.get("playerToken");

  if (isPlayer && playerToken) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${playerToken}`,
    };
  } else {
    options.headers = { ...options.headers, Authorization: `Bearer ${token}` };
  }

  if (contentType && contentType.includes("json")) {
    options.data = JSON.stringify(body);
  } else {
    options.data = body;
  }

  return instance(options);
};
