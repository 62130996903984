import { ContentState, EditorState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";

import draftToHtml from "draftjs-to-html";

const apiUrl = process.env.REACT_APP_API_URL;

export function chunkArray(array: any[], chunkSize: number): any[][] {
  const result: any[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

export const convertToISODate = (value: string | undefined) => {
  if (!value) return;
  const event = new Date(Number(value));
  return `${event.getDate()} / ${event.getMonth()} / ${event.getFullYear()}`;
};

export const findISODate = (value: string | undefined) => {
  if (!value) return;
  const event = new Date(value);
  return `${event.getDate()} / ${
    event.getMonth() + 1
  } / ${event.getFullYear()}`;
};

export function capitalizeFirstLetter(str: string) {
  return str.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
    return letter.toUpperCase();
  });
}

// string By split to point in point is string length
export function splitStringByPoint(inputString: string, point: number) {
  if (inputString.length <= point) {
    return [inputString]; // If the string length is less than or equal to the point, return the whole string
  }

  let result = [];
  let startIndex = 0;
  while (startIndex < inputString.length) {
    let splitIndex = inputString.indexOf(".", startIndex + point);
    if (splitIndex === -1) {
      // If no point found after the current point, push the remaining string
      result.push(inputString.substring(startIndex));
      break;
    } else {
      // Push substring from startIndex to splitIndex
      result.push(inputString.substring(startIndex, splitIndex + 1));
      startIndex = splitIndex + 1;
    }
  }
  return result;
}

// React-RTE helper

export const stringToDraft = (value: string | undefined) => {
  if (!value) {
    return EditorState.createEmpty();
  }
  const contentBlock = htmlToDraft(value);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};

export const draftToString = (draftData: EditorState) => {
  return draftToHtml(convertToRaw(draftData.getCurrentContent()));
};

// export const htmlToDraftjs = (data: string) => {
//   const contentBlock = htmlToDraft(data ?? "");
//   const contentState = ContentState.createFromBlockArray(
//     contentBlock.contentBlocks
//   );
//   const editorState = EditorState.createWithContent(contentState);
//   return editorState;
// };

// export const randomizationPushToArray = (arr1: any[], arr2: any[]) => {
//   if (arr1?.length === 0 && arr2?.length !== 0) return arr2;
//   if (arr2?.length === 0 && arr1?.length !== 0) return arr1;
//   if (arr2?.length === 0 && arr1?.length === 0) return [];

//   arr1.forEach((_: any, i: number) =>
//     arr2.splice(Math.floor(Math.random() * arr2.length), 0, arr2[i])
//   );
//   return arr1;
// };

// export const randomizationPushToArray = (arr1:any, arr2:any) => {
//   const result = [];

//   while (arr1.length > 0 || arr2.length > 0) {
//     if (arr1.length > 0 && (arr2.length === 0 || Math.random() < 0.5)) {
//       result.push(arr1.shift());
//     } else if (arr2.length > 0) {
//       result.push(arr2.shift());
//     }
//   }

//   return result;
// };
export const randomization = (array: any) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const randomizationPushToArray = (arr1: any[], arr2: any[]) => {
  const arr1New = Array.from(arr1);
  const arr2New = Array.from(arr2);
  arr2New.forEach((e, i) => {
    const pushIndex = Math.floor(Math.random() * arr1New.length);
    arr1New.splice(pushIndex + 10, 0, arr2New[i]);
  });
  return arr1New;
};

export const convertStringArrayToKeyValue = (arr: [string]) => {
  return arr.map((str) => ({ value: str, label: str }));
};

export const MadeImageUrl = (img_url: string | undefined) => {
  if (!img_url) return "";
  if (img_url?.includes("http")) {
    return img_url;
  }
  // return `${FINAL_BASE_URL}/file/${img_url}`;
  return `${apiUrl}/file/${img_url}`;
};

export const reactSelectOutputToArray = (value: Array<Object | null>) => {
  return value?.map((e: any) => e?.value);
};
export const ArrayToReactSelectOutput = (value: any) => {
  return value?.map((e: any) => ({ label: e, value: e }));
};

export const FindFirstPTagString = (htmlContent: string) => {
  let doc = new DOMParser().parseFromString(htmlContent, "text/html");
  return doc.querySelector("p")?.textContent;
};

//Convarte of date formete (January 27, 2024)
export const ConvertLocaleDate = (dateString: string) => {
  const date = new Date(dateString);
  const newFormattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return newFormattedDate;
};

// BLOG DATE FORMETE(25th Jan 2024)

export function humanReadableFormatDate(inputDate: string) {
  const dateObject = new Date(inputDate);

  // Get day with suffix
  const dayWithSuffix = getDayWithSuffix(dateObject.getDate());

  // Get month abbreviation
  const monthAbbreviation = getMonthAbbreviation(dateObject.getMonth());

  // Get the year
  const year = dateObject.getFullYear();

  // Format the date string
  const formattedDate = `${dayWithSuffix} ${monthAbbreviation} ${year}`;

  return formattedDate;
}
export function humanReadableFormatTime(inputDate: string) {
  const dateObject = new Date(inputDate);

  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  // Format the date string
  const formattedDate = `${hours} : ${minutes} : ${seconds}`;

  return formattedDate;
}

// Helper function to get the day with suffix
export function getDayWithSuffix(day: string | number) {
  if (day + "" >= 11 + "" && day + "" <= 13 + "") {
    return day + "th";
  }

  switch (Number(day) % 10) {
    case 1:
      return day + "st";
    case 2:
      return day + "nd";
    case 3:
      return day + "rd";
    default:
      return day + "th";
  }
}

// Helper function to get the month abbreviation
function getMonthAbbreviation(month: number) {
  const monthsAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return monthsAbbreviations[Number(month)];
}

// device movable component potion

export const positionOfElement = (
  elementWidth: number,
  elementHeight: number,
  clientX: number,
  clientY: number,
  deviceWidth: number,
  deviceHeight: number
) => {
  if (clientX < 0) {
    clientX = 0;
  } else if (deviceWidth) {
    const compareWidth = deviceWidth - elementWidth;
    if (clientX > compareWidth) {
      clientX = compareWidth;
    }
  }
  if (clientY < 0) {
    clientY = 0;
  } else if (deviceHeight) {
    const compareHeight = deviceHeight - elementHeight;
    if (clientY > compareHeight) {
      clientY = compareHeight;
    }
  }
  return { x: clientX, y: clientY };
};

export const breakPointsValue = (
  width: any,
  xs: any,
  sm: any,
  md: any,
  lg: any,
  xl: any,
  xxl: any
) => {
  if (width && width >= 1400) {
    return xxl;
  }
  if (width && width >= 1200) {
    return xl;
  }
  if (width && width >= 992) {
    return lg;
  }
  if (width && width >= 768) {
    return md;
  }
  if (width && width >= 576) {
    return sm;
  }
  if (width && width < 576) {
    return xs;
  }
  return xs;
};
